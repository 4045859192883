import React, { useState, useEffect } from 'react';
import './App.css';
import CountdownTimer from './CountdownTimer/CountdownTimer';

import desktopImage from './albu.png'
import mobileImage from './albu_phone.png'

function App() {
  const imageUrl = useWindowWidth() >= 650 ? desktopImage : mobileImage;
  return (
    <div className="App" style={{backgroundImage: `url(${imageUrl})` }}>
      <header className="App-header">
       
      <h1>Albufeira bois</h1>
        <CountdownTimer 
          countdownTimestampMs={1657110900000}/>
       
      </header>
    </div>
  );
}

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth ] = useState(window.innerWidth);

  useEffect(() => {
      const handleWindowResize = () => {
          setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleWindowResize);
      return () => window.removeEventListener('resize', handleWindowResize);
  },[]);

  return windowWidth;
};

export default App;
