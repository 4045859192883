import {useState, useEffect} from 'react';
import  './CountdownTimer.css';
import {getRemainingTimeUntilMsTimestamp} from './Utils/CountdownTimerUtils'

const defaultRemainingTime = {
    seconds: '00',
    minutes: '00',
    hours: '00',
    days: '00'
}

const CountdownTimer = ({countdownTimestampMs}) => {
    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

    useEffect(() => {
        const intervalId = setInterval(()=> {
            updateRemainingTime(countdownTimestampMs);
        }, 1000);
        return () => clearInterval(intervalId)
    },[countdownTimestampMs])

    function updateRemainingTime (countdown) {
        setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    }
    return(
        <div className="countdown-timer wrapper">
            <div className=" wrapper_border">
                <span className="time_style">{remainingTime.days}</span>
                <span className="text_style">Days</span>
            </div>
            <div className="wrapper_border">
                <span className="two-numbers time_style">{remainingTime.hours}</span>
                <span className="text_style">Hours</span>
            </div>
            <div className="wrapper_border">
                <span className="two-numbers time_style">{remainingTime.minutes}</span>
                <span className="text_style">Min</span>
            </div>
            <div className="wrapper_border">
                <span className="two-numbers time_style">{remainingTime.seconds}</span>
                <span className="text_style">Sec</span>
            </div>
        </div>
    )
}

export default CountdownTimer;